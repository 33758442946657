<div id="step-wrapper">
    <div *ngIf="stepType !== 'first'" class="step-goback" style="">
        <button 
        	class="button-general" 
        	style="" 
        	[disabled]="prevDisable" 
        	(click)="goPrevious()">{{prevText}}
        </button>
    </div>
    <div *ngIf="stepType !== 'last'" class="step-next"  style="">
        <button 
        	class="button-general"
			[disabled]="nextDisable" 
        	(click)="goNext()">{{nextText}}
        </button>
    </div>
    <ng-content></ng-content>
</div>