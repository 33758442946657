<div class="app-modal-title"></div>
<div class="app-modal-content">
	<div class="system-template-layer">
		<div style="font-size:16px;">Select a seed / template event layer</div>
		<div>
			<select name="template-layer" [(ngModel)]="selectedTemplateLayer" (ngModelChange)="onSelectTemplateLayer()">
				<option *ngFor="let layer of eventLayers" [ngValue]="layer">{{layer.name}}</option>
			</select>
		</div>
	</div>
	<div *ngFor="let sysCol of systemColumns;let idx = index;" class="column-mapping-line">
		<div class="col-name">
			{{sysCol.attrDisplay}}
		</div>
		<div class="col-mapped">
			<input *ngIf="!selectedTemplateLayer" name="system-{{idx}}" disabled [(ngModel)]="sysCol.mappedCol">
			<select *ngIf="selectedTemplateLayer" name="system-{{idx}}" [(ngModel)]="sysCol.mappedCol" (ngModelChange)="onMapSystemColumn(sysCol)">
				<ng-container *ngFor="let col of systemColumnOptions">
					<option *ngIf="col.type===sysCol.type" [ngValue]="col.name">{{col.name}}</option>
				</ng-container>
			</select>
		</div>
	</div>
</div>
<div class="app-modal-footer">
	<div class="button-group">
		<button class="button-general" md-raised-button (click)="cancel()">Cancel</button>
		<button class="button-general" md-raised-button (click)="apply()" [disabled]="!hasChanged">Ok</button>
	</div>
</div>