<div class="attribute-line">
	<label>Email: </label>
	<input type="email" name="email" disabled [(ngModel)]="email" placeholder="User Email" required/>
</div>	
<div class="attribute-line">
	<label>Password: </label>
	<input type="password" name="password" [(ngModel)]="password" placeholder="Password" required/>
</div>
<div class="attribute-line">
	<button class="button-general" style="width: 120px;" [disabled]="!email||!password" (click)="generateApiKey()">Generate ApiKey</button>		
	<textarea readonly>{{apiKey}}</textarea>
</div>
