<div style="height: 100%;">
	<div class="top-line">
		<div class="apply-button">
				<button class="button-general" [disabled]="!hasChanged||isApplying" (click)="save()">Apply</button>
		</div>
	</div>
	<div class="meta-section section-state">
		<div class="section-title">State</div>
		<div class="section-content">
			<select name="state" [(ngModel)]="selectedState" (ngModelChange)="changeState()">
				<option *ngFor="let state of states" [ngValue]="state">{{state.state}}</option>
			</select>
		</div>
	</div>
	<div class="meta-section section-map-service">
		<div class="section-title">Map Service URL</div>
		<div class="section-content">
			<input name="map-service" class="map-service-url" [ngClass]="{'input-invalid': isUrlInvalid}" [(ngModel)]="mapServiceUrl" (ngModelChange)="changeMapUrl()" (keyup.enter)="testMapUrl(mapServiceUrl)" (blur)="testMapUrl(mapServiceUrl)">
		</div>
	</div>
	<div class="meta-section section-database">
		<div class="section-title">Database</div>
		<div class="section-content">
			<div class="database-line">
				<div class="database-item">
					<div>Type</div>
					<div>
						<select name="database-type-list" [(ngModel)]="selectedDatabaseType" (ngModelChange)="changeDatabaseType()">
							<option *ngFor="let db of databaseTypeList" [ngValue]="db">{{db.displayName}}</option>
						</select>
					</div>
				</div>
				<div class="database-item">
					<div>Sde Owner</div>
					<div>
						<input name="sde-owner" disabled [(ngModel)]="databaseConfig.sdeOwner" (ngModelChange)="changeDatabase('sdeOwner')">
					</div>
				</div>
			</div>
			<div class="database-line">
				<div class="database-item">
					<div>Name</div>
					<div>
						<input name="database-name" disabled [(ngModel)]="databaseConfig.databaseName" (ngModelChange)="changeDatabase('databaseName')">
					</div>
				</div>
				<div class="database-item">
					<div>Data Owner</div>
					<div>
						<input name="data-owner" disabled [(ngModel)]="databaseConfig.dataOwner" (ngModelChange)="changeDatabase('dataOwner')">
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="routeLayerName" class="meta-section section-route">
		<div class="section-title">Lrs Network <span class="route-layer-name">{{routeLayerName ? ' - ' + routeLayerName : ''}}</span></div>
		<div class="section-content">
			<div class="route-layer-name">

			</div>
			<div class="route-columns">
				<div *ngFor="let routeCol of routeColumns;" class="column-mapping-line">
					<div class="col-name">
						{{routeCol.attrDisplay}}
					</div>
					<div *ngIf="routeCol.attrValue!=='routeName'" class="col-mapped">
						{{routeCol.mappedCol}}
					</div>
					<div *ngIf="routeCol.attrValue==='routeName'" class="col-mapped col-mapped-select">
						<select name="route-name" [(ngModel)]="routeCol.mappedCol" (ngModelChange)="changeRouteName()">
							<option *ngFor="let col of routeNameColList" [ngValue]="col.name">{{col.name}}</option>
						</select>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="hasEventLayer" class="meta-section section-lrs-columns">
		<div class="section-title">Lrs Events</div>
		<div class="section-content">
			<div class="lrs-columns">
				<div *ngFor="let lrsCol of lrsColumns;" class="column-mapping-line">
					<div class="col-name">
						{{lrsCol.attrDisplay}}
					</div>
					<div class="col-mapped">
						{{lrsCol.mappedCol}}
					</div>
				</div>
			</div>
			<div *ngIf="showLrsColumnsExtra" class="lrs-columns-extra">
				<div *ngFor="let lrsCol of lrsColumnsExtra;" class="column-mapping-line">
					<div class="col-name">
						{{lrsCol.attrDisplay}}
					</div>
					<div class="col-mapped">
						{{lrsCol.mappedCol}}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="hasEventLayer" class="meta-section section-system-columns">
		<div class="section-title">
			<span>System-maintained Columns</span>
			<i class="glyphicons glyphicons-pencil edit-icon" (click)="editSystemColumns()"></i>
		</div>
		<div class="section-content">
			<div *ngFor="let sysCol of systemColumns;let idx = index;" class="column-mapping-line">
				<div class="col-name">
					{{sysCol.attrDisplay}}
				</div>
				<div class="col-mapped">
					{{sysCol.mappedCol}}
					<!-- <select name="system-{{idx}}" [(ngModel)]="sysCol.mappedCol" (ngModelChange)="onMapSystemColumn(sysCol)">
						<ng-container *ngFor="let col of systemColumnOptions">
							<option *ngIf="col.type===sysCol.type" [ngValue]="col.name">{{col.name}}</option>
						</ng-container>
					</select> -->
				</div>
			</div>
		</div>
	</div>
</div>