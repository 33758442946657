<div class="step-page">
	<div class="step-title">Agency Profile</div>
	<div class="step-content">
		<div>
			<button class="button-general"
			(click)="update()"
			[disabled]="profileForm.invalid|| !profileForm.dirty || !profileForm.touched">Apply</button>
		</div>
		<form #profileForm="ngForm">
			<div class="content">
				<div class="options-wrap">
					<label>Agency Name</label>
					<input name="shortName" [(ngModel)]="agencyName" required>
				</div>
				<div class="options-wrap">
					<label>Description</label>
					<input name="longName"[(ngModel)]="agencyDesc">
				</div>
				<div class="options-wrap">
					<label>Administrator Email:</label>
					<input name="userEmail" [(ngModel)]="adminEmails" disabled>
				</div>
			</div>
		</form>
	</div>
	<div class="step-footer">
		<app-page-footer></app-page-footer>
	</div>
</div>
