<div ngDraggable ngResizable class="socket-msg-container" [hidden]="isClosed" [style.width]="isContent ? '320px' : '100px'">
	<div class="socket-msg-topbar">
		<!-- <div *ngIf="isContent" class="socket-msg-topbar-title">Process Monitor</div> -->
		<div class="socket-msg-topbar-icons" [ngClass]="{'topbar-icons-collapse': !isContent}">
			<!-- <i class="glyphicons"
			[ngClass]="{'glyphicons-minus': isContent, 'glyphicons-unchecked': !isContent}"
			(click)="toggleContent()">
			</i> -->
			<i class="glyphicons glyphicons-plus" (click)="close()"></i>
		</div>
	</div>
	<div *ngIf="isContent" class="socket-msg-content">
		<table id="process-table">
			<div class="table-body">
				<div class="table-row"
					*ngFor="let process of processes; let idx = index;"
					(click)="clickProcess(idx)">
					<div class="table-td">
						<div>
							<div style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
								{{process.type}}&nbsp;&nbsp;&nbsp;&nbsp;<span class="end-time">{{process.endTime}}</span>
							</div>
						</div>
					</div>
					<ng-container *ngIf="process.status!=='Canceling'">
					<div class="table-td status-icon">
						<div [ngSwitch]="process.status">
							<i *ngSwitchCase="'Completed'" class="glyphicons glyphicons-ok"></i>
							<i *ngSwitchCase="'Error'" class="glyphicons glyphicons-remove"></i>
							<i *ngSwitchCase="'Canceled'" class="glyphicons glyphicons-remove"></i>
							<i *ngSwitchCase="'Preparing'" class="loader"></i>
							<i *ngIf="process.status !== 'Completed' && process.status !== 'Error' && process.status !== 'Canceled' && process.status !== 'Preparing'" class="loading-dots"></i>
							<!-- <div [ngClass]="{'loading-dots': process.status !== 'Completed' && process.status !== 'Error'}">
								{{process.status}}
							</div> -->
						</div>
					</div>
					<div class="table-td operation-column">
						<div class="">
							<button *ngIf="process.status!=='Completed'&&process.status!=='Error'&&process.status!=='Canceled'"
								class="cancel-btn"
								title="Cancel Process"
								(click)="clickCancel(idx)">
								Cancel
							</button>
							<i class="glyphicons glyphicons-list"
								title="View Logs"
								(click)="clickViewLogs(idx)">
							</i>
							<i *ngIf="process.status==='Completed'"
								class="glyphicons glyphicons-map"
								title="View Data"
								[ngClass]="{'disabled-icon': process.status !== 'Completed'}"
								(click)="clickViewData(idx)">
							</i>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="process.status==='Canceling'">
					<div class="table-td processing-text">
							Canceling
							<i class="loading-dots"></i>
					</div>
				</ng-container>
				</div>
				<ng-container *ngIf="processes.length < 3">
					<div *ngFor="let i of Arr(3 - processes.length).fill(i)" class="table-row empty-row">
						<div class="table-td"></div>
						<div class="table-td"></div>
						<div class="table-td"></div>
					</div>
				</ng-container>
			</div>
		</table>
	</div>
</div>
