<div *ngIf="isActivateUserOnly" id="reset-pw-wrapper"  class="auth-wrapper">
	<div class="auth-logo">
		<img [src]="imgUrl" class="auth-logo-img">
	</div>
	<form #resetForm="ngForm" name="resetForm">
	<div class="auth-title">
		<label>{{isUpdateState ? 'Set Password' : 'Reset Password'}}</label>
	</div>				
		<div style="">
			<input type="password" name="password" class="field-vertical" 
				[(ngModel)]="password" 
				placeholder="Please enter new password"  
				appValidateEqual="rePassword" reverse="true" 
				required />
			<input type="password" name="rePassword" class="field-vertical" 
				[(ngModel)]="rePassword" 
				placeholder="Please re-enter new password" 
				appValidateEqual="password" 
				required />
		</div>
		<div class="btn-bottom-wrap">
			<button class="button-general" (click)="resetPw()" [disabled]="!this.resetForm.valid || isReseting">OK</button>	
		</div>
	</form>
</div>