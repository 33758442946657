<div class="common-datagrid">
    <ngx-datatable style="width:100%;" [style.height]="hidePaging?'100%':'calc(100% - 50px)'"
		#table
		class="material"
		[rows]="rows"
		[columns]="columns"
		[selectionType]="selectionType"
		[columnMode]="columnMode"
		[selected]="selectedRows"
		[headerHeight]="headerH"
		[rowHeight]="rowH"
		[rowClass]="rowClass"
		[messages]="messages"
		[scrollbarV]="isScrollbarV"
		[scrollbarH]="isScrollbarH"
		[selectCheck]="selectCheck"
		(select)="onSelectRows($event)"
		(activate)="onActivate($event)"
		(tableContextmenu)="onContextmenu($event)"
		>
	</ngx-datatable>
	<ng-container *ngIf="!hidePaging">
		<app-common-table-paging
			#tablePaging
			[isPaging]="isPaging"
			[totalNum]="totalNum"
			[currentRowNum]="rows.length"
			[pageSize]="pageSize"
			[footerHeight]="footerH"
			(paging)="onPaging($event)">
		</app-common-table-paging>
	</ng-container>
</div>
