<div class="app-modal-title">{{title}}</div>
<div class="app-modal-content">
	<div>
		{{message}}
	</div>
</div>
<div class="app-modal-footer">
	<div class="button-group">
		<button class="button-general" md-raised-button (click)="dialogRef.close(false)">{{noBtnText}}</button>
		<button class="button-general" md-raised-button (click)="dialogRef.close(true)">{{yesBtnText}}</button>
	</div>
</div>