<!-- <div class="nag-title">{{selectedNag?.name}}</div> -->
<div class="nag-content">
	<div class="content-left nag-cates">
		<div *ngFor="let cate of nagCates" [ngClass]="{'selected-cate': selectedCate===cate}" (click)="selectCate(cate)">{{cate.name}}</div>
	</div>
	<div class="content-middle">
		<div class="nag-options">
			<div class="nag-filter">
				<label style="width:100px;">Standard Name</label>
				<input style="width:calc(100% - 104px);" [(ngModel)]="filterStr" (ngModelChange)="changeFilter()" placeholder="filter standard names">
			</div>
			<div class="nag-download">
				<button class="button-general" (click)="download()">Download</button>
			</div>
		</div>
		<div class="nag-detail" (contextmenu)="onTableParentContextmenu($event)">
			<app-common-datagrid
				#namesTable
				[columns]="columns"
				[rows]="standardNames"
				[selectedRows]="selectedRows"
				[selectionType]="'single'"
				[isPaging]="true"
				[pageSize]="paging.pageSize"
				[totalNum]="totalCount"
				(paging)="setPage($event)"
				(select)="onSelectStandardName()"
				(tableContextmenu)="onTableContextmenu($event)">
			</app-common-datagrid>
		</div>
	</div>
	<div class="content-right">
		<div class="nag-alias">
			<div class="alias-title">Alias</div>
			<div class="alias-list" (contextmenu)="onAliasContextmenu($event)">
				<div *ngFor="let ali of aliasNames" (contextmenu)="onAliasContextmenu($event, ali)">{{ali.name}}</div>
			</div>
		</div>
		<div class="nag-map" [id]="mapId"></div>
	</div>
</div>

<ng-template #placeTypeTempl let-row="row" let-column="column" let-index="index">
	<div *ngIf="row[placeTypesCol]&&(row[placeTypesCol].indexOf(column.prop)>-1)">
		<i class="glyphicons glyphicons-ok"></i>
	</div>
</ng-template>

<context-menu #StandardOptions>
	<ng-template contextMenuItem [enabled]="true" (execute)="addStandardName()">Add a Standard Name</ng-template>
	<ng-template contextMenuItem [enabled]="optionsEnable.standardEdit" (execute)="editStandardName($event)">Edit</ng-template>
	<ng-template contextMenuItem [enabled]="optionsEnable.standardDelete" (execute)="deleteStandardName($event)">Delete</ng-template>
</context-menu>
<context-menu #AliasOptions>
	<ng-template contextMenuItem [enabled]="optionsEnable.aliasAdd" (execute)="addAlias()">Add an Alias</ng-template>
	<ng-template contextMenuItem let-item [enabled]="optionsEnable.aliasEdit" (execute)="editAlias($event)">Edit</ng-template>
	<ng-template contextMenuItem [enabled]="optionsEnable.aliasDelete" (execute)="deleteAlias($event)">Delete</ng-template>
</context-menu>
