<div id="data-upload-page" class="step-page">
	<div class="step-title">
		<div class="title-text">Data Load - ARNOLD LRS</div>
		<div class="title-other" [routerLink]="['/portal/lbe-setting/upload/events']">
			Load Sections Here
			<i class="glyphicons glyphicons-new-window-alt"></i>
		</div>
	</div>
	<div class="step-content">
		<form #uploadForm="ngForm">
			<div class="arnold-layer-wrap">
				<div class="line-wrap lrs-info">
					<div class="line-2-part">
						<label>Lrs Name</label>
						<div style="width:calc(86% - 131px);">
							<input required name="lrs-name" [(ngModel)]="lrsInfo.name">
						</div>
					</div>
					<div class="lrs-desc">
						<label>Description</label>
						<div>
							<input required name="lrs-desc" [(ngModel)]="lrsInfo.desc">
						</div>
					</div>
				</div>
				<div class="line-wrap lrs-info">
					<div>
						<label>Route ID Column</label>
						<div>
							<select
								class="route-id-col"
								name="route-id-col"
								[(ngModel)]="selectedFile?.matchingColumns[1].matchedColumn"
								required>
								<option *ngFor="let field of selectedFile?.content.fields" [ngValue]="field">{{field.name}}</option>
							</select>
						</div>
					</div>
					<div class="lrs-precision">
						<label>Measure Precision</label>
						<input name="lrs-precision" type="number" [(ngModel)]="lrsInfo.precision" required>
					</div>
					<div class="lrs-uom">
						<label style="text-align: left;">Unit of Measure</label>
						<select name="lrs-uom" [(ngModel)]="lrsInfo.uom">
							<option *ngFor="let uom of uomList" [value]="uom.value">{{uom.name}}</option>
						</select>
					</div>
				</div>
				<div class="line-wrap lrs-file">
					<div class="file-name" *ngIf="selectedFile">
						<div>
							FILE:
						</div>
						<div>
							{{selectedFile?.name}}
						</div>
						<i class="glyphicons glyphicons-remove" (click)="removeFile()"></i>
					</div>
				</div>
				<div class="layer-table" style="display:none;">
					<div class="line-wrap">
						<div>
							<label>Route ID Column</label>
							<select
								name="route-id-col"
								[(ngModel)]="selectedFile?.matchingColumns[0].matchedColumn"
								required>
								<option *ngFor="let header of selectedFile?.headers" [ngValue]="header">{{header}}</option>
							</select>
						</div>

					</div>
				</div>
				<div class="preview-table">
					<!-- Do not use nfIf to replace hidden here. since the arnoldFilesElem needs to be selected -->
					<div [hidden]="selectedFile" class="drop-file-watermark" appFileDropZone (onDropFiles)="onDropFiles($event)">
						<div class="drop-file-watermark-content">Drop Files Here To Upload</div>
						<div class="choose-file-section">
							<i class=" glyphicons glyphicons-cloud-upload" (click)="arnoldFiles.click()"></i>
							<input #arnoldFiles
								type="file"
								id="arnoldfile-uploader"
								name="arnoldfile-uploader"
								(change)="loadArnoldFiles(arnoldFiles.files)"
								multiple
								hidden>
							<div class="choose-file-txt">choose file</div>
						</div>
					</div>
					<div *ngIf="selectedFile" class="watermark"></div>
					<app-common-datagrid
						class="material"
						[columnMode]="'force'"
						[rows]="previewRows"
						[columns]="previewColumns"
						[selectionType]="'single'"
						[totalNum]="previewRows.length"
						[rowH]="38"
					></app-common-datagrid>
				</div>
			</div>
		</form>
	</div>
	<div class="step-footer">
		<app-step-control [stepType]="'first'" (onNext)="goNext()" [nextDisable]="uploadForm.form.invalid">
		</app-step-control>
	</div>
</div>
