<div id="retrieve-pw-wrapper"  class="auth-wrapper">
	<div class="auth-logo">
		<img [src]="imgUrl" class="auth-logo-img">
	</div>
	<form #retrieveForm="ngForm" name="retrieveForm">
		<div class="auth-title">
			<label class="">Reset Password</label>
		</div>
		<input type="email" name="email" class="field-vertical" [(ngModel)]="email" placeholder="Please enter your email" required/>			
		<div class="link-wrapper">
			<a [routerLink]="['/auth/login']" class="">Log In</a>
			<a [routerLink]="['/auth/reister']" class="">Sign up</a>
		</div>
		<div class="btn-bottom-wrap">		
			<button class="button-general" (click)="retrieve()" [disabled]="!retrieveForm.form.valid || isRetrieving">Retrieve</button>
		</div>
	</form>
</div>