<div>
	<div class="step-title">Linear Reference System</div>
	<div class="top-content">
		<div>Set Default LRS for LBE:</div>
		<div class="right">
			<button class="button-general" (click)="apply()">Apply</button>
		</div>
	</div>
	<div>
		<app-common-datagrid
			#lrsTable
			class="material"
			[columnMode]="'force'"
			[rows]="rows"
			[columns]="columns"
			[selectionType]="null"
			[hidePaging]="true">
		</app-common-datagrid>
		<ng-template #lrsSelectionTempl let-row="row" let-column="column" let-index="index">
			<div>
				<input name="selection" type="radio" [value]="row.isDefault" (click)="selectLrs(row)">
			</div>
		</ng-template>
	</div>
	<app-page-footer [routerPath]="['/portal/lbe-setting']" hidden></app-page-footer>
</div>
