<div class="step-page">
	<div class="step-title">LBM Configuration</div>
	<div class="step-content">
		<div class="license-count-container">
			<label>License Count:</label>
			<input name="count" type="number" [(ngModel)]="licenseCount"/>
			<div *ngIf="licenseCount!==licenseCountOrigin" [@enterAnimation] class="count-btns">
				<button (click)="updateCount()">Apply</button>
				<button (click)="cancelCountChange()">Cancel</button>
			</div>
		</div>
		<div class="lbm-table-container">
			<app-common-datagrid
				#LbmStatesTable
				[columns]="columns"
				[rows]="lbmStates"
				[selectionType]="undefined"
				[hidePaging]="true"
			></app-common-datagrid>
		</div>
	</div>
</div>

<ng-template #EditCellTempl let-row="row">
	<div>
		<i class="glyphicons glyphicons-edit" (click)="clickEdit(row)"></i>
	</div>
</ng-template>
