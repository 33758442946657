<div class="slider-labels sub-levels">
	<div *ngFor="let level of subLevels">
		{{level.name}}
	</div>
</div>
<div style="display: flex;">
	<mat-slider min="0" max="2" step="1"
		[(ngModel)]="sliderIdx"
		[disabled]="disableSlider"
		(change)="changeSliderValue()"
	></mat-slider>
</div>
