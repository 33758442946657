<div id="subscription-manage" class="step-page">
	<div class="step-title">User Management</div>
	<div class="step-content">
		<div>
			<label>LinearBench Account Users</label>
			<input type="text" name="filter-bar" [(ngModel)]="userFilter" (change)="changeFilter()">
		</div>
		<div class="user-table-wrap">
			<app-common-datagrid
				#userTable
				[columns]="columns"
				[rows]="rows"
				[selectionType]="undefined"
				[hidePaging]="true"
				[rowClass]="getRowClass.bind(this)">
			</app-common-datagrid>
		</div>
		<!-- <div *ngIf="false" class="user-table-wrap">
			<div class="user-table">
				<table id="user-table">
					<thead>
						<tr>
							<th>User Email</th>
							<th>User Name</th>
							<th>Admin</th>
							<th *ngFor="let subscription of subscriptions">
								{{subscription.appName}}
							</th>
							<th>Add / Remove</th>
						</tr>							
					</thead>
					<tbody>
						<tr *ngFor="let user of users; let idx = index;">
							<td class="user-email">
								<div>
									{{user.userEmail}}				
								</div>
							</td>
							<td class="user-name">
								<div>
									{{user.userName}}				
								</div>
							</td>
							<td class="user-admin">
								<div>
									<mat-slide-toggle
									[(ngModel)]="user.isAdmin"
									(change)="onToggleUserAdmin(user)">
								</mat-slide-toggle>				
								</div>
							</td>
							<td *ngFor="let subscription of subscriptions" class="user-sub">
								<div>
									<input 
										name="{{idx}}-{{subscription.appName}}" 
										type="checkbox" 
										[(ngModel)]="user.userSubs[subscription.appName]" 
										(change)="changeUserSub(idx, subscription.appName)"/>
								</div>
							</td>
							<td class="user-add-remv">
								<div class="">
									<i
									class="glyphicons glyphicons-minus-sign" 
									title="Remove User" 
									[ngClass]="{'icon-disable': user.userEmail === currentUser.userEmail}"
									(click)="removeUser(idx)"></i>
								</div>
							</td>		
						</tr>	
						<tr>
							<td class="user-email">
								<div *ngIf="currentAuth && currentAuth.type==='LB'">
									<input 
										name="new-user" 
										type="text" 
										placeholder="Type an email address to add a new user here"
										[(ngModel)]="newUser.userEmail">
								</div>
							</td>
							<td class="user-name">
							</td>
							<ng-container *ngIf="currentAuth && currentAuth.type==='LB'">
								<td *ngFor="let subscription of subscriptions" class="user-sub">
									<div>
										<input 
											name="new-user-{{subscription.appName}}" 
											type="checkbox" 
											[(ngModel)]="newUser.userSubs[subscription.appName]"/>
									</div>								
								</td>
							</ng-container>
							<td class="user-add-remv">
								<div class="">
									<i
									class="glyphicons glyphicons-plus-sign" 
									title="Add User" 
									[ngClass]="{'disabled-icon': !newUser.userEmail}"
									(click)="addUser()"></i>
								</div>
							</td>	
						</tr>							
					</tbody>	
				</table>
			</div>
		</div> -->
	</div>
	<div class="step-footer">
		<app-page-footer></app-page-footer>
	</div>	
</div>
	
<ng-template #userEmailCell let-row="row" let-column="column">
	<div *ngIf="row.isNewUser" class="new-user-email">
		<div *ngIf="currentAuth && currentAuth.type==='LB'" title="Type an email to add a new user here">
			<input 
				name="new-user" 
				type="text" 
				placeholder="Type an email to add a new user here"
				[(ngModel)]="newUser.userEmail">
		</div>
	</div>
	<div *ngIf="!row.isNewUser">
		{{row[column.prop]}}
	</div>
</ng-template>
<ng-template #isAdminCell let-column="column" let-row="row">
	<mat-slide-toggle
		[(ngModel)]="row[column.prop]"
		(change)="onToggleUserAdmin(row)">
	</mat-slide-toggle>
</ng-template>
<ng-template #subscriptionCell let-column="column" let-row="row">
	<div>
		<input 
			name="{{rows.indexOf(row)}}-{{column.prop}}" 
			type="checkbox" 
			[(ngModel)]="row[column.prop]" 
			(change)="changeUserSub(row, column.prop)"/>
	</div>
</ng-template>
<ng-template #operationCell let-row="row" let-column="column">
	<div *ngIf="row[column.prop].hasRemove">
		<i
		class="glyphicons glyphicons-minus-sign" 
		title="Remove User" 
		[ngClass]="{'icon-disable': row.userEmail === currentUser.userEmail}"
		(click)="removeUser(rows.indexOf(row))"></i>
	</div>
	<div *ngIf="row[column.prop].hasAdd">
		<i
		class="glyphicons glyphicons-plus-sign" 
		title="Add User" 
		[ngClass]="{'disabled-icon': !newUser.userEmail}"
		(click)="addUser()"></i>
	</div>
</ng-template>