<div class="app-modal-title">Renew</div>
<div class="app-modal-content">
	<div class="options">
		<div>
			<div class="option-label">Term Options:</div>
			<div class="option-content">
				<div class="slider-labels">
					<div *ngFor="let value of sliderValues">
						{{value.name}}
					</div>
				</div>
				<div>
					<mat-slider min="0" max="1" step="1" [(ngModel)]="selectedSlider" (change)="changeUnit(selectedSlider)"></mat-slider>
				</div>					
			</div>
		</div>
		<div class="date-option">
			<label class="option-label">Renew Length:</label>
			<div >
				<div>
					<input style="display:inline-block" type="number" [(ngModel)]="quantity" (change)="updateRenewDate(quantity,selectedSlider)" required>
				</div>
				
				<div>{{unit}}</div>
			</div>
			<label class="option-label end-date-label">End Date:</label>
			<div class="end-date-content">
				<!-- <input id="end-date" type="date" name="end-date" [(ngModel)]="endDate" disabled> -->
				<div>{{endDate}}</div>
			</div>
		</div>
	</div>
	
</div>
<div class="app-modal-footer">
	<div class="button-group">
		<button class="button-general" md-raised-button (click)="close()">Cancel</button>
		<button class="button-general" md-raised-button (click)="apply(selectedSlider)" [disabled]="!quantity">Apply</button>
	</div>
</div>