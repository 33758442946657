<div class="app-modal-title">{{title}}</div>
<div class="app-modal-content">
	<div>
		<input name="alias-name" placeholder="name" style="width:100%;" [(ngModel)]="aliasName">
	</div>
</div>
<div class="app-modal-footer">
	<div class="button-group">
		<button class="button-general" md-raised-button (click)="cancel()">Cancel</button>
		<button class="button-general" md-raised-button (click)="apply()">Apply</button>
	</div>
</div>
