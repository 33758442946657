<div class="app-modal-title">Symbology Setting</div>
<div class="app-modal-content">
	<form #symbologyForm="ngForm" style="height:100%;">
		<div class="upper-container">
			<div class="general-left">
				<div class="option-container">
					<div class="option-label">Layer Name</div>
					<div *ngIf="action==='edit'" class="option-value">
						<input disabled name="layer-name" [(ngModel)]="symbology.layerName">
					</div>
					<div *ngIf="action==='add'" class="option-value">
						<select name="layer-name" [(ngModel)]="propsAdd.selectedLayer" (ngModelChange)="selectLayer()">
							<option *ngFor="let layer of propsAdd.layers" [ngValue]="layer">{{layer.name}}</option>
						</select>
					</div>
				</div>
				<div class="option-container">
					<div class="option-label">Symbology Name</div>
					<div class="option-value">
						<input name="symbology-name" type="text" [(ngModel)]="symbology.spec.name" required>
					</div>
				</div>
				<div class="option-container">
					<div class="option-label">Symbology Column</div>
					<div class="option-value">
						<select name="symbology-column" [(ngModel)]="selectedSymbCol" (ngModelChange)="changeSymbCol()" required>
							<option hidden [ngValue]="null"></option>
							<option *ngFor="let col of columns" [ngValue]="col">{{col.displayedName}}</option>
						</select>
						<span *ngIf="!selectedSymbCol" style="font-size:11px;color:red;">*Please select a column.</span>
					</div>
				</div>
				<div class="option-container value-type">
					<div class="option-label">Value Type</div>
					<div class="value-type-content">
						<div class="value-type-col-1">
							<div *ngFor="let vt of valueTypes; let idx = index;">
								<input type="radio" name="value-type-{{idx}}" [(ngModel)]="valueType" [value]="vt" [disabled]="!selectedSymbCol||vt.checkDisable()" (ngModelChange)="changeSymbolType()">
								<div style="display: inline-block;">{{vt.name}}</div>
							</div>
						</div>
						<div class="value-type-col-2">
							<div class="interval-options" *ngIf="valueType?.value==='INTERVAL'">
								<div class="interval-opt">
									<label>Min Value</label>
									<input name="min-value" type="number" [(ngModel)]="symbology.spec.minVal" (ngModalChange)="onChangeMinMaxInt('min')">
								</div>
								<div class="interval-opt">
									<label>Max Value</label>
									<input name="max-value" type="number" [(ngModel)]="symbology.spec.maxVal" (ngModalChange)="onChangeMinMaxInt('max')">
								</div>
								<div class="interval-opt">
									<label>Legend Group</label>
									<input name="group-number"
										type="number"
										[(ngModel)]="intLegendGroupNum"
										(ngModelChange)="onChangeGroupNumberInt($event)"
										[disabled]="(!symbology.spec.minVal&&symbology.spec.minVal!==0)||(!symbology.spec.maxVal&&symbology.spec.maxVal!==0)">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="general-right">
				<textarea placeholder="Symbol Description"></textarea>
				<div class="option-container">
					<div class="option-label">Tooltip Column</div>
					<div class="option-value">
						<select name="tooltip-column" [(ngModel)]="selectedTipCol" (ngModelChange)="changeTipCol()">
							<option hidden [ngValue]="null"></option>
							<option *ngFor="let col of columns" [ngValue]="col">{{col.displayedName}}</option>
						</select>
					</div>
				</div>
				<div class="option-container">
					<div class="option-label">Label Column</div>
					<div class="option-value">
						<select name="lab-column" [(ngModel)]="selectedLabCol" (ngModelChange)="changeLabCol()">
							<option hidden [ngValue]="null"></option>
							<option *ngFor="let col of columns" [ngValue]="col">{{col.displayedName}}</option>
						</select>
					</div>
				</div>
			</div>
		</div>
		<div class="lower-container">
			<div class="symbology-legend" [ngClass]="{'symbology-legend-long': !compatibles||!compatibles.length}">
				<div class="legend-title"><div>Legend Definition</div></div>
				<ng-container [ngSwitch]="valueType?.value">
					<ng-container *ngSwitchCase="'NOMINAL'">
						<div class="legend-content">
							<div class="nominal-container">
								<div class="legend-line">
									<div class="nominal-value">
										Symbol Value
									</div>
									<div class="nominal-label">
										Label
									</div>
									<div class="nominal-color">
										Color
									</div>
									<div class="nominal-style">
										Style
									</div>
									<div class="nominal-remove">
									</div>
								</div>
								<ng-container *ngFor="let symbol of symbolNom.symbols; let idx = index;">
									<div *ngIf="!symbol.val||checkType(symbol.val)!=='object'" class="legend-line">
										<div class="nominal-value" [title]="symbol.val">
											<input name="nominal-val-{{idx}}" placeholder="value" [(ngModel)]="symbol.val" [matAutocomplete]="nomVals">
											<mat-autocomplete #nomVals="matAutocomplete" [class]="'mat-autocomplete-panel-general'">
												<mat-option *ngFor="let val of nominalValuesDB" [value]="val">{{val}}</mat-option>
											</mat-autocomplete>
										</div>
										<div class="nominal-label" [title]="symbol.lab">
											<input name="nominal-label-{{idx}}" placeholder="label" [(ngModel)]="symbol.lab">
										</div>
										<div class="nominal-color">
											<app-common-tango-colorpicker [selectedColor]="symbol.color" (colorChange)="onColorChange($event, symbol)"></app-common-tango-colorpicker>
										</div>
										<div class="nominal-style">
											<app-common-symbol-stylepicker [selectedShape]="symbol.style" [selectedSize]="symbol.size" [color]="symbol.color" (styleChange)="onStyleChange($event, symbol)"></app-common-symbol-stylepicker>
										</div>
										<div class="nominal-remove">
											<i class="glyphicons glyphicons-bin" title="Remove Symbol" (click)="removeSymbolNom(idx)"></i>
										</div>
									</div>
								</ng-container>
							</div>
							<div class="nominal-operations">
								<i class="glyphicons glyphicons-plus" title="Add a symbol" (click)="addSymbolNom()"></i>
							</div>
						</div>
					</ng-container>
					<ng-container *ngSwitchCase="'INTERVAL'">
						<div *ngIf="selectedSymbCol?.isIntervalable" class="legend-content">

							<div class="interval-container" *ngIf="symbolInt.symbols.length">
								<div class="legend-line">
									<div class="interval-value" title="From value">
										From
									</div>
									<div class="interval-value" title="To value">
										To
									</div>
									<div class="interval-label" title="Label">
										Label
									</div>
									<div class="interval-color" title="Color">
										Color
									</div>
									<div class="interval-style" title="Style">
										Style
									</div>
									<div class="interval-remove"></div>
								</div>
								<ng-container *ngFor="let symbol of symbolInt.symbols;trackBy:trackByFn; let idx = index;">
									<div *ngIf="symbol.val&&checkType(symbol.val)==='object'" class="legend-line">
										<div class="interval-value" [title]="symbol.val[0]">
											<input name="interval-fromval-{{idx}}" type="number" placeholder="from value" [(ngModel)]="symbol.val[0]" (ngModelChange)="changeSymbolValInt(symbol, 0)">
											<!-- pattern="^\d*(\.\d{0,2})?$" -->
										</div>
										<div class="interval-value" [title]="symbol.val[1]">
											<input name="interval-toval-{{idx}}" type="number" placeholder="to value" [(ngModel)]="symbol.val[1]" (ngModelChange)="changeSymbolValInt(symbol, 1)">
										</div>
										<div class="interval-label" [title]="symbol.lab">
											<input name="interval-label-{{idx}}" placeholder="label" [(ngModel)]="symbol.lab">
										</div>
										<div class="interval-color">
											<app-common-tango-colorpicker [selectedColor]="symbol.color" (colorChange)="onColorChange($event, symbol)"></app-common-tango-colorpicker>
										</div>
										<div class="interval-style">
											<app-common-symbol-stylepicker [selectedShape]="symbol.style" [selectedSize]="symbol.size" [color]="symbol.color" (styleChange)="onStyleChange($event, symbol)"></app-common-symbol-stylepicker>
										</div>
										<div class="interval-remove">
											<i class="glyphicons glyphicons-bin" title="Remove Symbol" (click)="removeSymbolInt(idx)"></i>
											<i class="glyphicons glyphicons-plus" title="Add a symbol" (click)="addSymbolInt(idx)"></i>
										</div>
									</div>
								</ng-container>
							</div>
							<div class="interval-operations">
								<i class="glyphicons glyphicons-plus" title="Add a symbol" (click)="addSymbolInt(-1)"></i>
							</div>
						</div>
					</ng-container>
					<ng-container *ngSwitchCase="'ORDINAL'"></ng-container>
				</ng-container>
			</div>
			<div *ngIf="compatibles&&compatibles.length" class="symbology-compatibles">
				<div class="compatible-title">Pick from available symbologys</div>
				<div class="compatible-items-container">
					<div class="compatible-item" *ngFor="let comp of compatibles" [ngClass]="{'compatible-item-selected': comp===compatibleSymbology}" (click)="selectCompatibles(comp)">{{comp.spec.name}}</div>
				</div>
			</div>
		</div>
	</form>
</div>
<div class="app-modal-footer">
	<div class="button-group">
		<button class="button-general" md-raised-button (click)="cancel()">Cancel</button>
		<button class="button-general" md-raised-button (click)="apply()" [disabled]="symbologyForm.invalid">Apply</button>
	</div>
</div>







	<form *ngIf="false" #symbologyForm="ngForm" style="height:100%;">
		<div class="upper-container">
			<div class="general-left">
				<div class="option-container">
					<div class="option-label">Layer Name</div>
					<div *ngIf="action==='edit'" class="option-value">
						<input disabled name="layer-name" [(ngModel)]="symbology.layerName">
					</div>
					<div *ngIf="action==='add'" class="option-value">
						<select name="layer-name" [(ngModel)]="propsAdd.selectedLayer" (ngModelChange)="selectLayer()">
							<option *ngFor="let layer of propsAdd.layers" [ngValue]="layer">{{layer.name}}</option>
						</select>
					</div>
				</div>
				<div class="option-container">
					<div class="option-label">Symbology Name</div>
					<div class="option-value">
						<input name="symbology-name" type="text" [(ngModel)]="symbology.spec.name" required>
					</div>
				</div>
				<div class="option-container">
					<div class="option-label">Symbology Column</div>
					<div class="option-value">
						<select name="symbology-column" [(ngModel)]="selectedSymbCol" (ngModelChange)="changeSymbCol()" required>
							<option hidden [ngValue]="null"></option>
							<option *ngFor="let col of columns" [ngValue]="col">{{col.displayedName}}</option>
						</select>
						<span *ngIf="!selectedSymbCol" style="font-size:11px;color:red;">*Please select a column.</span>
					</div>
				</div>
				<div class="option-container">
					<div class="option-label">Tooltip Column</div>
					<div class="option-value">
						<select name="tooltip-column" [(ngModel)]="selectedTipCol" (ngModelChange)="changeTipCol()">
							<option hidden [ngValue]="null"></option>
							<option *ngFor="let col of columns" [ngValue]="col">{{col.displayedName}}</option>
						</select>
					</div>
				</div>
				<div class="option-container">
					<div class="option-label">Label Column</div>
					<div class="option-value">
						<select name="tooltip-column" [(ngModel)]="selectedLabCol" (ngModelChange)="changeLabCol()">
							<option hidden [ngValue]="null"></option>
							<option *ngFor="let col of columns" [ngValue]="col">{{col.displayedName}}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="general-right">
				<textarea placeholder="Symbol Description"></textarea>
				<div class="value-type">
					<div>Value Type</div>
					<div>
						<ng-container *ngFor="let vt of valueTypes; let idx = index;">
							<input type="radio" name="value-type-{{idx}}" [(ngModel)]="valueType" [value]="vt" [disabled]="!selectedSymbCol||vt.checkDisable()" (ngModelChange)="changeSymbolType()">
							<div style="display: inline-block;">{{vt.name}}</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
		<div class="lower-container">
			<div class="symbology-legend" [ngClass]="{'symbology-legend-long': !compatibles||!compatibles.length}">
				<div class="legend-title"><div>Legend Definition</div></div>
				<ng-container [ngSwitch]="valueType?.value">
					<ng-container *ngSwitchCase="'NOMINAL'">
						<div class="legend-content">
							<div class="nominal-container">
								<div class="legend-line">
									<div class="nominal-value">
										Symbol Value
									</div>
									<div class="nominal-label">
										Label
									</div>
									<div class="nominal-style">
										Style
									</div>
									<div class="nominal-color">
										Color
									</div>
									<div class="nominal-remove">
									</div>
								</div>
								<ng-container *ngFor="let symbol of symbolNom.symbols; let idx = index;">
									<div *ngIf="!symbol.val||checkType(symbol.val)!=='object'" class="legend-line">
										<div class="nominal-value" [title]="symbol.val">
											<input name="nominal-val-{{idx}}" placeholder="value" [(ngModel)]="symbol.val" [matAutocomplete]="nomVals">
											<mat-autocomplete #nomVals="matAutocomplete" [class]="'mat-autocomplete-panel-general'">
												<mat-option *ngFor="let val of nominalValuesDB" [value]="val">{{val}}</mat-option>
											</mat-autocomplete>
										</div>
										<div class="nominal-label" [title]="symbol.lab">
											<input name="nominal-label-{{idx}}" placeholder="label" [(ngModel)]="symbol.lab">
										</div>
										<div class="nominal-style">
											<app-common-symbol-stylepicker [selectedShape]="symbol.style" [selectedSize]="symbol.size" [color]="symbol.color" (styleChange)="onStyleChange($event, symbol)"></app-common-symbol-stylepicker>
										</div>
										<div class="nominal-color">
											<app-common-tango-colorpicker [selectedColor]="symbol.color" (colorChange)="onColorChange($event, symbol)"></app-common-tango-colorpicker>
										</div>
										<div class="nominal-remove">
											<i class="glyphicons glyphicons-bin" title="Remove Symbol" (click)="removeSymbolNom(idx)"></i>
										</div>
									</div>
								</ng-container>
							</div>
							<div class="nominal-operations">
								<i class="glyphicons glyphicons-plus" title="Add a symbol" (click)="addSymbolNom()"></i>
							</div>
						</div>
					</ng-container>
					<ng-container *ngSwitchCase="'INTERVAL'">
						<div *ngIf="selectedSymbCol?.isIntervalable" class="legend-content">
							<div class="interval-options">
								<div class="interval-opt">
									<label>Minimum Value</label>
									<input name="min-value" type="number" [(ngModel)]="symbology.spec.minVal" (ngModalChange)="onChangeMinMaxInt('min')">
								</div>
								<div class="interval-opt">
									<label>Maximum Value</label>
									<input name="max-value" type="number" [(ngModel)]="symbology.spec.maxVal" (ngModalChange)="onChangeMinMaxInt('max')">
								</div>
								<div class="interval-opt">
									<label>Legend Group</label>
									<input name="group-number"
										type="number"
										[(ngModel)]="intLegendGroupNum"
										(ngModelChange)="onChangeGroupNumberInt($event)"
										[disabled]="(!symbology.spec.minVal&&symbology.spec.minVal!==0)||(!symbology.spec.maxVal&&symbology.spec.maxVal!==0)">
								</div>
								<!-- <div class="interval-opt">
									<label>Precision</label>
									<input name="group-number"
										type="number"
										title="The number intervals for symbology values."
										[(ngModel)]="intervalPrecision"
										(ngModelChange)="onChangeIntervalPrecision()">
								</div> -->
							</div>
							<div class="interval-container" *ngIf="symbolInt.symbols.length">
								<div class="legend-line">
									<div class="interval-value" title="From value">
										From
									</div>
									<div class="interval-value" title="To value">
										To
									</div>
									<div class="interval-label" title="Label">
										Label
									</div>
									<div class="interval-style" title="Style">
										Style
									</div>
									<div class="interval-color" title="Color">
										Color
									</div>
									<div class="interval-remove"></div>
								</div>
								<ng-container *ngFor="let symbol of symbolInt.symbols;trackBy:trackByFn; let idx = index;">
									<div *ngIf="symbol.val&&checkType(symbol.val)==='object'" class="legend-line">
										<div class="interval-value" [title]="symbol.val[0]">
											<input name="interval-fromval-{{idx}}" type="number" placeholder="from value" [(ngModel)]="symbol.val[0]" (ngModelChange)="changeSymbolValInt(symbol, 0)">
											<!-- pattern="^\d*(\.\d{0,2})?$" -->
										</div>
										<div class="interval-value" [title]="symbol.val[1]">
											<input name="interval-toval-{{idx}}" type="number" placeholder="to value" [(ngModel)]="symbol.val[1]" (ngModelChange)="changeSymbolValInt(symbol, 1)">
										</div>
										<div class="interval-label" [title]="symbol.lab">
											<input name="interval-label-{{idx}}" placeholder="label" [(ngModel)]="symbol.lab">
										</div>
										<div class="interval-style">
											<app-common-symbol-stylepicker [selectedShape]="symbol.style" [selectedSize]="symbol.size" [color]="symbol.color" (styleChange)="onStyleChange($event, symbol)"></app-common-symbol-stylepicker>
										</div>
										<div class="interval-color">
											<app-common-tango-colorpicker [selectedColor]="symbol.color" (colorChange)="onColorChange($event, symbol)"></app-common-tango-colorpicker>
										</div>
										<div class="interval-remove">
											<i class="glyphicons glyphicons-bin" title="Remove Symbol" (click)="removeSymbolInt(idx)"></i>
											<i class="glyphicons glyphicons-plus" title="Add a symbol" (click)="addSymbolInt(idx)"></i>
										</div>
									</div>
								</ng-container>
							</div>
							<div class="interval-operations">
								<i class="glyphicons glyphicons-plus" title="Add a symbol" (click)="addSymbolInt(-1)"></i>
							</div>
						</div>
					</ng-container>
					<ng-container *ngSwitchCase="'ORDINAL'"></ng-container>
				</ng-container>
			</div>
			<div *ngIf="compatibles&&compatibles.length" class="symbology-compatibles">
				<div class="compatible-title">Pick from available symbologys</div>
				<div class="compatible-items-container">
					<div class="compatible-item" *ngFor="let comp of compatibles" [ngClass]="{'compatible-item-selected': comp===compatibleSymbology}" (click)="selectCompatibles(comp)">{{comp.spec.name}}</div>
				</div>
			</div>
		</div>
	</form>