<form *ngIf="true" #mapForm=ngForm class="map-setting">
	<div class="col-unit col-unit-1">
		<div class="basemap-section">
			<div *ngFor="let basemap of currentBasemaps; let index = index" class="basemap-item">
				<div class="basemap-actions">
					<i class="glyphicons glyphicons-plus" title="add" (click)="addNewBasemap()"></i>
					<i class="glyphicons glyphicons-minus" title="remove" [ngClass]="{'greyish' : currentBasemaps.length === 1}" (click)="removeBasemap(index)"></i>
				</div>
				<select name="{{'basemapSelect-' + index}}" class="basemap-type" [(ngModel)]="basemap.mapType" (ngModelChange)="onChangeBasemapType(basemap)" required >
					<!-- <option value=""></option> -->
					<ng-container *ngFor="let base of basemapTypes">
						<option *ngIf="!base.hidden" [ngValue]="base">{{base.name}}</option>
					</ng-container>
				</select>
				<input name="urlAddress" type="url" placeholder="URL" class="basemap-url" [disabled]="!basemap.mapType || !basemap.mapType.allowUrl">
			</div>
		</div>
		<div class="cursor-section">
			<div class="line-label">Map Cursor</div>
			<div class="line-value">
				<app-map-cursor-select (cursorChange)="currentCursor=$event"></app-map-cursor-select>
			</div>
		</div>
		<div class="route-section">
			<div class="line-label">Route Segment Backdrop</div>
			<div class="line-value color-picker">
				<input [(colorPicker)]="routeColor" [style.background]="routeColor" [cpOutputFormat]="'rgba'" (colorPickerChange)="onChangeRouteColor($event)"/>
			</div>
		</div>
	</div>
	<div class="col-unit col-unit-2">
		<div id="extent-map" class="map-section"></div>
		<div class="apply-section">
			<button class="button-general" [disabled]="mapForm.invalid||isApplying" (click)="apply()">Apply</button>
			<!-- [disabled]="mapForm.invalid || !mapForm.dirty  "  -->
		</div>
	</div>
</form>



























<form *ngIf="false" #mapForm=ngForm  class="content">
	<div class="content">
		<div class="top-content">
			<div class="title">Base Maps</div>
			<button class="button-general right" [disabled]="mapForm.invalid || !mapForm.dirty  " (click)="apply()">Apply</button>
			<!-- <button class="button-general right" [disabled]="!currentBasemaps[currentBasemaps.length-1].type || !currentCursor" (click)="apply()">Apply</button> -->
		</div>
		<div class="basemap-div">
			<div *ngFor="let basemap of currentBasemaps; let index = index" class="basemap-wrap">
				<select name="{{'basemapSelect-' + index}}" class="basemap-type" [(ngModel)]="currentBasemaps[index].type" required >
					<option value=""></option>
					<ng-container *ngFor="let base of basemapTypes">
						<option *ngIf="!base.hidden" [value]="base.type">{{base.name}}</option>
					</ng-container>
				</select>
				<input name="urlAddress" type="url" placeholder="URL" class="basemap-url" [disabled]="currentBasemaps[index].type !== 'ArcGIS Online'">
				<div class="basemap-options">
					<i class="glyphicons glyphicons-plus" title="add" (click)="addNewBasemap()"></i>
					<i class="glyphicons glyphicons-remove" title="remove" [ngClass]="{'greyish' : currentBasemaps.length === 1}" (click)="removeBasemap(index)"></i>
				</div>
			</div>
			<!-- <div class="basemap-wrap">
				<select class="basemap-type" [(ngModel)]="currentBasemaps[0].Type" >
					<option disabled selected hidden value="" style="color:lightgrey;">select new basemap</option>
					<option *ngFor="let type of basemapTypes" [value]="type">{{type}}</option>
				</select>
				<input type="url" placeholder="URL" class="basemap-url" [disabled]="currentBasemaps[index].Type !== 'ArcGIS Online'">
				<div class="basemap-options">
					<i class="glyphicons glyphicons-plus" title="add" (click)="addNewBasemap()"></i>
					<i class="glyphicons glyphicons-remove" title="remove" (click)="removeBasemap(index)"></i>
				</div>
			</div> -->
		</div>
		<div>
			<div class="line-label">Map Cursor Color</div>
			<div class="line-value">
			<!-- <img *ngFor="let cursor of cursorList" [src]="cursor.path">  -->
				<select name="map-cursor" [(ngModel)]="currentCursor" required>
					<option value=""></option>
					<option *ngFor="let cursor of cursorList" [value]="cursor.imageName" [ngStyle]="{'background-image' : 'url('+cursor.path+')'}">{{cursor.name}}
					</option>
					<!-- <option *ngFor="let cursor of cursorList" [value]="cursor.name" >{{cursor.name}}</option> -->
				</select>
			</div>
		</div>
		<div class="line-container">
			<div class="line-label">Initial Map Extents</div>
			<div class="line-value">
				<div id="extent-map"></div>
			</div>
		</div>
	</div>
</form>
