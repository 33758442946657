<div class="content-wrap">
    <div class="content-title">Please select one of the following layers to be ARNOLD Layer .</div>
    <div class="content-layers">
        <select
            name="arnold-layer"
            size="5"
            [(ngModel)]="arnoldLayer">
            <option *ngFor="let layer of layers" [ngValue]="layer">{{layer.name}}</option>
        </select>
    </div>
</div>
<div class="btn-wrap">
    <button class="button-general" md-raised-button (click)="close('ok')">Ok</button>
    <button class="button-general" md-raised-button (click)="close('cancel')">Cancel</button>
</div>
