<div id="portal-homepage" class="step-page">
	<div class="step-title">
		<div id="app-logo" >
			<div class="logo" title="LinearBench Potal Access"></div>
		</div>
		<div class="app-title">
			<div>
				LinearBench Portal - {{userInfo.customerName}}
			</div>
		</div>
		<div class="left-title">
			<i class="glyphicons glyphicons-user"></i>
			<div>{{userInfo.userEmail}}</div>
		</div>
	</div>
	<div class="step-content">
		<div class="home-left-container">
			<div class="my-subscriptions-title">
				<div>My Applications</div>
			</div>
			<div class="my-subscriptions-content">
				<div *ngFor="let sub of mySubscriptions;" class="subscription-row">
					<div *ngIf="envName === 'prod'" class="app-link" title="Open {{sub.appName}}" (click)="clickApp(sub.appName)">{{sub.appName}}</div>
					<div *ngIf="envName !== 'prod'">{{sub.appName}}</div>
					<div>{{sub.role}}</div>
				</div>
			</div>
			<!-- <div *ngIf="userInfo?.isAdmin" class="button-group">
				<button class="button-general" [routerLink]="['/portal/subscription-manage']">Subscription Management</button>
				<button class="button-general" [routerLink]="['/portal/user-manage']">User Management</button>
				<button class="button-general" [routerLink]="['/portal/role-manage']">Role Management</button>
			</div> -->
		</div>
		<div class="logout">
			<i class="glyphicons glyphicons-log-out" (click)="logout()"></i>
		</div>
	</div>
	<div class="step-footer">
	</div>
</div>
