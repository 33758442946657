<div *ngIf="!selectedApp">
	<h5 style="color: lightgrey;">You don't have any user subscriptions. please access user management or Subscription menu to confirm your infomation</h5>
</div>
<div *ngIf="selectedApp" id="subscription-manage" class="step-page">
	<div class="step-title">Role Management</div>
	<div class="step-content">
		<div class="top-content">
			<div class="option-label">Application</div>
			<div class="option-content">
				<select name="selected-app" [(ngModel)]="selectedApp" (change)="changeApp()">
					<option *ngFor="let app of apps" [ngValue]="app">{{app.name}}</option>
				</select>
			</div>
			<div>
				<button class="button-general" (click)="apply()" [disabled]="!hasChanges">Apply</button>
			</div>
		</div>
		<div class="table-wrap">
			<app-common-datagrid
				#dataTable
				[columns]="columns"
				[rows]="rows"
				[selectionType]="undefined"
				[hidePaging]="true"
			></app-common-datagrid>
		</div>
	</div>
	<div class="step-footer">
		<app-page-footer></app-page-footer>
	</div>
</div>
<ng-template #cellTempl let-row="row" let-column="column" let-value="value">
	<div>
		<input type="radio" name="{{rows.indexOf(row)}}-role"
		[(ngModel)]="row.role"
		[value] = "column.prop"
		(change)="onChangeRole(row)">
		<!-- [(ngModel)]="newRows[rows.indexOf(row)]" -->
		<!--  -->
	</div>
</ng-template>
