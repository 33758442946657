<div>
	<common-success-page [message]="message"></common-success-page>
</div>
<!-- <div *ngIf="!isShowMessage" id="register-verify-wrapper"  class="auth-wrapper">
	<div class="auth-logo">
		<img [src]="imgUrl" class="auth-logo-img">
	</div>
	<form #registerVerifyForm="ngForm" class="">
		<div class="auth-title">
			<label class="">Set a new password</label>
		</div>			
		<div style="">			
			<input name="password" 
				type="password"
				class="field-vertical" 
				[(ngModel)]="password" 
				placeholder="Admin Password" 
				required 
				appValidateEqual="rePassword" 
				reverse="true" />
			<password-strength-bar [password]="password" [barLabel]="'Password Strength'"></password-strength-bar>
			<input name="rePassword" 
				type="password"
				class="field-vertical" 
				[(ngModel)]="rePassword" 
				placeholder="Re-enter Password" 
				required 
				appValidateEqual="password">
		</div>
		<div class="link-wrapper" style="">
			<a [routerLink]="['/auth/retrieve-pw']" class="">Password Reset</a>
			<a [routerLink]="['/auth/login']" class="">Log In</a>
		</div>
		<div class="btn-bottom-wrap">
			<button class="button-general" (click)="verify()" [disabled]="!registerVerifyForm.form.valid || !password || isVerifying">Set Password</button>
		</div>
		
	</form>
</div>				 -->