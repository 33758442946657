<div class="app-modal-title">System-Maintained Columns Mapping</div>
<div class="app-modal-content">
	<div *ngFor="let sysCol of systemColumns;let idx = index;" class="column-mapping-line">
		<div class="col-name">
			{{sysCol.attrDisplay}}
		</div>
		<div class="col-mapped">
			<select name="system-{{idx}}" [(ngModel)]="sysCol.mappedCol" (ngModelChange)="onMapSystemColumn(sysCol)">
				<ng-container *ngFor="let col of systemColumnOptions">
					<option *ngIf="col.type===sysCol.type" [ngValue]="col.name">{{col.name}}</option>
				</ng-container>
			</select>
		</div>
	</div>
</div>
<div class="app-modal-footer">
	<div class="button-group">
		<button class="button-general" md-raised-button (click)="cancel()">Cancel</button>
		<button class="button-general" md-raised-button (click)="apply()" [disabled]="!hasChanged">Apply</button>
	</div>
</div>

