<div class="app-modal-title">Time out!</div>
<div class="app-modal-content">
	<div>You will be logged out in {{countdown}} second(s).</div>
</div>
<div class="app-modal-footer">
	<div class="button-group">
		<button md-raised-button class="button-general" (click)="dialogRef.close('stay')">Stay</button>
		<button md-raised-button class="button-general" (click)="dialogRef.close('leave')">Leave</button>

	</div>
</div>
