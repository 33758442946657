<div id="subscription-manage" class="step-page">
	<div class="step-title">Subscription Management</div>
	<div class="step-content grid-row-container">
		<div class="grid-cell left-container">
			<div class="button-line">
				<div class="sub-operations">
					<div>
						<!-- NOTES!!!! -->
						<!-- For expired subscriptions, they can be subscribed but not renewed -->
						<button [disabled]="!selectedSub||selectedSub.state === null || selectedSub.state==='expired'" class="button-general" (click)="upgrageSub()">Upgrade</button>
					</div>
					<div>
						<button [disabled]="!selectedSub || selectedSub.state===null || selectedSub.state==='expired'" class="button-general" (click)="renewSub()">Renew</button>
					</div>
					<div *ngIf="selectedSub">
						<button *ngIf="!selectedSub.state || selectedSub.state==='expired'" class="button-general" (click)="addSub()">Subscribe</button>
						<button *ngIf="selectedSub.state!==null && selectedSub.state!=='expired' " class="button-general" (click)="deleteSub(selectedRows[0])">Unsubscribe</button>
					</div>
					<div *ngIf="!selectedSub">
						<button disabled class="button-general">Subscribe</button>
					</div>
				</div>
			</div>
			<div class="subscription-table">
				<app-common-datagrid
					#dataTable
					[columns]="columns"
					[columnMode]="'force'"
					[rows]="rows"
					[rowClass]="getRowClass.bind(this)"
					[selectedRows]="selectedRows"
					[hidePaging]="true"
					(select)="onSelectRow($event.selected[0])"
					[selectCheck]="selectCheck.bind(this)"
				></app-common-datagrid>
			</div>

			<div class="information-container" *ngIf="selectedSub">
				<div class="application-brief" style="vertical-align:middle;">
					<h3>Application</h3>
					<h3>Brief</h3>
				</div>
				<div class="sub-plan-container">
					<div *ngIf="subPlan" style="height: 100%;">
						<app-common-datagrid
							#planTable
							[columnMode]="'force'"
							[columns]="subPlanColumns"
							[rows]="subPlan"
							[selectionType]="undefined"
							[hidePaging]="true"
						></app-common-datagrid>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="grid-cell right-container">
			<div *ngIf="subPlan" style="height: 100%;">
				<app-common-datagrid
					#planTable
					[columns]="subPlanColumns"
					[rows]="subPlan"
					[selectionType]="undefined"
					[hidePaging]="true"
				></app-common-datagrid>
			</div>
		</div> -->
	</div>
	<div class="step-footer">
		<app-page-footer></app-page-footer>
	</div>
</div>

<ng-template #operationCell let-value="value" let-row="row" let-column="column">
	<div *ngIf="value">
		<button class="button-general button-small" (click)="showSubPlan(row.appName)" [disabled]="!row[column.prop].details">Plan Details</button>
	</div>
	<!-- <div *ngIf="value==='edit'" title="Edit subscription">
		<i class="glyphicons glyphicons-edit" (click)="editSub()"></i>
	</div> -->
	<!-- <div *ngIf="value==='add' && apps.length >= rows.length" title="Add a new subscription">
		<i class="glyphicons glyphicons-plus-sign" (click)="addSub()"></i>
	</div> -->
</ng-template>



<ng-template #labelCell let-row="row" let-value="value">
	<div [title]="row.status==0?'This feature is under development.':''">
		{{value}}
		<i *ngIf="row.status==0" class="glyphicons glyphicons-construction-cone"></i>
	</div>
</ng-template>
<ng-template #checkMarkCell let-value="value">
	<div *ngIf="value==='t'">
		<i class="glyphicons glyphicons-ok"></i>
	</div>
	<div *ngIf="value==='f'">
		<i class="glyphicons glyphicons-remove"></i>
	</div>
	<div *ngIf="value!=='t'&&value!=='f'">
		{{value}}
	</div>
</ng-template>
