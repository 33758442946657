<div class="content">
	<div class="auth-logo">
		<img [src]="imgUrl" class="auth-logo-img">
	</div>
	<div class="content-right">
		<div class="email-enter">
			<input name="email" placeholder="Enter email to proceed to login..." [(ngModel)]="userEmail" (keyup.enter)="enterEmail()">
		</div>
		<div class="btn-bottom-wrap">
			<button class="button-general" (click)="enterEmail()" [disabled]="!userEmail">Sign in</button>
		</div>
		<div class="link-wrapper">
			<a [routerLink]="['/auth/register']" class="">Register</a>
		</div>
	</div>
</div>