<div>
	<div style="font-size:14px;padding-bottom:7px;">Auth Type</div>
	<div class="auth-type-item">
		<label>Type</label>
		<select name="auth-type" [(ngModel)]="selectedAuth.type" (ngModelChange)="onChangeAuthType()">
			<option *ngFor="let authType of authTypeList" [ngValue]="authType.value">{{authType.name}}</option>
		</select>
	</div>
	<ng-container *ngIf="selectedAuth" [ngSwitch]="selectedAuth.type">
		<div *ngSwitchCase="'Esri'">
			<div class="auth-type-item">
				<label>clientId</label>
				<input name="client-id" [(ngModel)]="selectedAuth.secret.clientId">
			</div>
			<div class="auth-type-item">
				<label>url</label>
				<input name="url" [(ngModel)]="selectedAuth.secret.url">
			</div>
		</div>
		<div *ngSwitchCase="'AD'">
			<div class="auth-type-item">
				<label>baseDN</label>
				<input name="base-dn" [(ngModel)]="selectedAuth.secret.baseDN">
			</div>
			<div class="auth-type-item">
				<label>domain</label>
				<input name="domain" [(ngModel)]="selectedAuth.secret.domain">
			</div>
			<div class="auth-type-item">
				<label>url</label>
				<input name="url" [(ngModel)]="selectedAuth.secret.url">
			</div>
			<div class="auth-type-item">
				<label>user name</label>
				<input name="user-name" [(ngModel)]="selectedAuth.secret.username">
			</div>
		</div>
	</ng-container>
</div>