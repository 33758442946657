<div>
	<div *ngFor="let menu of menus" class="menu" [hidden]="menu.hide">
		<div class="menu-name"
			[title]="menu.tooltipTxt || ''"
			[routerLink]="menu.toPath"
			[preserveFragment]="true">
			{{menu.name}}
		</div>
		<div *ngIf="menu.contents" class="menu-contents">
			<div *ngFor="let content of menu.contents"
				class="content-name"
				[title]="content.tooltipTxt || ''"
				[routerLink]="content.toPath"
				[preserveFragment]="true">
				{{content.name}}
			</div>
		</div>
	</div>
</div>