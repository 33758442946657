<div class="step-page">
	<div class="step-title">Gistic Users</div>
	<div class="step-content">
		<div>
			<div class="user-row">
				<label>User Email</label>
				<div>Site Admin</div>
			</div>
			<div *ngFor="let user of gisticUsers" class="user-row">
				<label>{{user.email}}</label>
				<div>
					<mat-slide-toggle
						[(ngModel)]="user.isSA"
						(change)="onToggleSA(user)">
					</mat-slide-toggle>
				</div>
			</div>
		</div>
	</div>
</div>