<div id="register-wrapper"  class="auth-wrapper">
	<div class="auth-logo">
		<img [src]="imgUrl" class="auth-logo-img">
	</div>
	<form #registerForm="ngForm" class="">
		<div class="auth-title">
			<label class="">Register An Agency</label>
		</div>			
		<div>			
			<input name="newAgency"
				class="field-vertical" 
				type="text"
				[(ngModel)]="newAgency.fullName" 
				placeholder="Agency Full Name"  
				/>
			<input name="newAgencyShort"
				class="field-vertical" 
				type="text"
				[(ngModel)]="newAgency.shortName" 
				placeholder="Agency Name" 
				required />				

			<input name="email" 
				type="email"
				class="field-vertical" 
				[(ngModel)]="email" 
				placeholder="Admin Email" 
				required/>

			<input type="password" name="password" class="field-vertical" 
				[(ngModel)]="password" 
				placeholder="Enter password"  
				appValidateEqual="rePassword" reverse="true" 
				required />
			<input type="password" name="rePassword" class="field-vertical" 
				[(ngModel)]="rePassword" 
				placeholder="Re-enter password" 
				appValidateEqual="password" 
				required />
			<div class="region-select">
				<label>Country: </label>
				<select required name="country" class="field-vertical" [(ngModel)]="selectedCountry" (ngModelChange)="onChangeCountry()">
					<option *ngFor="let country of countryList" [ngValue]="country">{{country.name}}</option>
				</select>
			</div>
			<div class="region-select">
				<label>{{stateLabel}}: </label>
				<select required name="state" class="field-vertical" [(ngModel)]="selectedState" (ngModelChange)="onChangeState()">
					<option *ngFor="let state of stateList" [ngValue]="state">{{state.name}}</option>
				</select>
			</div>
		</div>
		<!-- <div class="app-sub" title="Optional: Pick an app to subscribe">
			<div class="item-label">Subscription: </div>
			<div class="item-content">
				<select name="apps" [(ngModel)]="selectedApp" (ngModelChange)="changeApp()">
					<option [ngValue]="null"></option>
					<option *ngFor="let app of apps" [ngValue]="app" [disabled]="app.name==='GIV'">{{app.name}}</option>
				</select>
			</div>
		</div> -->

		<!-- <div class="state-list" *ngIf="selectedApp&&selectedApp.name==='ENJ'">
			<div class="item-label">State: </div>
			<div class="item-content">
				<select name="state" [(ngModel)]="selectedState" required>
					<option *ngFor="let state of states" [ngValue]="state">{{state.state}}</option>
				</select>
			</div>
		</div> -->
		
		<div class="link-wrapper" style="">
			<a [routerLink]="['/auth/retrieve-pw']" class="">Password Reset</a>
			<a [routerLink]="['/auth/login']" class="">Log In</a>
		</div>
		<div class="btn-bottom-wrap">
			<button class="button-general" (click)="registerAgency()" [disabled]="!registerForm.form.valid || (!newAgency.fullName) || isRegistering">Register</button>
		</div>
		
	</form>
</div>