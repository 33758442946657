<div class="app-modal-title">{{title}}</div>
<div class="app-modal-content">
	<div class="content-left" [ngClass]="{'full-width': !placeTypes.length}">
		<div class="standard-name">
			<label>Name</label>
			<input name="standard-name" [(ngModel)]="standardName" required>
		</div>
		<div class="county-list" *ngIf="countyList.length">
			<label>County</label>
			<select name="county-list" [(ngModel)]="parentCounty">
				<option [ngValue]="null"></option>
				<option *ngFor="let county of countyList" [ngValue]="county">{{county.name}}</option>
			</select>
		</div>
	</div>
	<div *ngIf="placeTypes.length" class="place-types">
		<div class="place-types-title">Place Types</div>
		<div class="place-types-list">
			<div *ngFor="let pt of placeTypes; let idx = index;">
				<input name="place-type-{{idx}}" type="checkbox" [(ngModel)]="pt.selected">
				<label>{{pt.name}}</label>
			</div>
		</div>
	</div>
</div>
<div class="app-modal-footer">
	<div class="button-group">
		<button class="button-general" md-raised-button (click)="cancel()">Cancel</button>
		<button class="button-general" md-raised-button (click)="apply()" [disabled]="!standardName">Apply</button>
	</div>
</div>
