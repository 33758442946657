<div id="data-upload-page" class="step-page">
	<div class="step-title">
		<div class="title-text">Data Load - Section Files</div>
		<div class="title-other" [routerLink]="['/portal/lbe-setting/upload/arnold']">
			Load Arnolds Here
			<i class="glyphicons glyphicons-new-window-alt"></i>
		</div>
	</div>
	<div class="step-content">
		<form #uploadForm="ngForm">
			<div class="line-wrap">
				<div class="file-spec">
					<div class="file-selection">
						<button class="button-general" (click)="eventFiles.click()">Select Files</button>
						<select [(ngModel)]="fileType" name="eventfile-type" (ngModelChange)="updateFileType()">
							<option *ngFor="let type of fileTypes" [ngValue]="type">{{type.name}}</option>
						</select>
						<input #eventFiles
							type="file"
							id="eventfile-uploader"
							name="eventfile-uploader"
							(change)="loadEventFiles(eventFiles.files)"
							[attr.accept]="fileType.value"
							multiple hidden>
					</div>
					<div class="file-separator">
						<label>Separator</label>
						<select [(ngModel)]="separator" name="separator" (change)="updateSeparator()">
							<option *ngFor="let sep of separators" [ngValue]="sep">{{sep.name}}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="layer-table" appFileDropZone (onDropFiles)="onDropFiles($event)">
				<div *ngIf="!eventLayers?.length" class="drop-file-watermark">
					<div class="drop-file-watermark-content">Drop Files Here To Upload</div>
				</div>
				<app-common-datagrid
					class="material"
					[columnMode]="'force'"
					[rows]="layersRows"
					[columns]="layersColumns"
					[selectionType]="'single'"
					[hidePaging]="true"
					[rowH]="38"
					(select)="selectFile($event)"
				></app-common-datagrid>
			</div>
				<div class="preview-table">
					<div class="watermark"></div>
					<app-common-datagrid
						class="material"
						[columnMode]="'force'"
						[rows]="previewRows"
						[columns]="previewColumns"
						[selectionType]="'single'"
						[totalNum]="previewRows.length"
					></app-common-datagrid>
				</div>
		</form>
	</div>
	<div class="step-footer">
		<app-step-control [stepType]="'first'" (onNext)="upload()" [nextDisable]="uploadForm.form.invalid">
		</app-step-control>
	</div>
</div>

<ng-template #columnTempl let-row="row" let-column="column">
	<div>
		<select name="{{column.prop}}-{{layersRows.indexOf(row)}}" [(ngModel)]="row[column.prop].matchedColumn" [required]="row[column.prop].required==='re'">
			<option [ngValue]="null"></option>
			<option *ngFor="let header of row.headers">{{header}}</option>
		</select>
	</div>
</ng-template>
