<div class="app-modal-title">Update</div>
<div class="app-modal-content">
	<div class="info-container">
		<div>
			<label>Project Name</label>
			<input [(ngModel)]="projectName">
		</div>
		<div>
			<label>Project Description</label>
			<input [(ngModel)]="projectDesc">
		</div>
		<div>
			<label>Enable</label>
			<div style="display: inline-block;">
				<label class="button-switch">
					<input type="checkbox" [(ngModel)]="projectMobile">
					<span class="switch-slider round">{{projectMobile ? 'on' : 'off'}}</span>
				</label>
			</div>
		</div>
	</div>
</div>
<div class="app-modal-footer">
	<div class="button-group">
		<button class="button-general" md-raised-button (click)="cancel()">Cancel</button>
		<button class="button-general" md-raised-button (click)="apply()" [disabled]="!projectDesc||!projectName">Apply</button>
	</div>
</div>
