<div class="app-modal-title">Fields Visibility & Sequence</div>
<div class="app-modal-content">
	<div class="column-table">
		<div class="column-table-header">
			<div *ngFor="let header of headers">{{header.displayName}}</div>
		</div>
		<div class="column-table-content">
			<div *ngFor="let col of columns; let idx = index;" class="column-item-wrapper">
				<div class="column-item draggable-container" draggable (onDragEnd)="onDragEnd($event, idx)">
					<div class="column-name" title="{{col.name}}">
						{{col.name}}
					</div>
					<div class="column-visib">
						<input name="visib-{{idx}}" type="checkbox" [disabled]="col.disabled" [(ngModel)]="col.visib" (ngModelChange)="onToggleColumnVisib(col)">
					</div>
					<div class="column-seq">
						<i *ngIf="idx>0" class="glyphicons glyphicons-chevron-up" title="Move up" (click)="goUp(idx)"></i>
						<i *ngIf="idx<columns.length-1" class="glyphicons glyphicons-chevron-down" title="Move down" (click)="goDown(idx)"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="app-modal-footer">
	<div class="button-group">
		<button class="button-general" md-raised-button (click)="cancel()">Cancel</button>
		<button class="button-general" md-raised-button (click)="apply()" [disabled]="!hasChanged">Apply</button>
	</div>
</div>
