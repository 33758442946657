<div class="app-selection">
	<div class="">
		<select name="selected-app" [(ngModel)]="selectedApp" (change)="changeApp()">
			<option *ngFor="let app of apps" [ngValue]="app">{{app.name}}</option>
		</select>
	</div>
</div>
<div class="ft-table">
	<app-common-datagrid
		#namesTable
		[columns]="columns"
		[rows]="ftSettings"
		[selectedRows]="selectedRows"
		[selectionType]="undefined"
		hidePaging="true"
		(select)="onSelectFt($event)">
	</app-common-datagrid>
</div>
<div class="btn-options">
	<button class="button-general" (click)="apply()">Apply</button>
</div>

<!-- TODO -->
<ng-template #FactorTempl let-row="row" let-column="column" let-index="index">
	<ng-container *ngIf="row[column.prop]" [ngSwitch]="row[column.prop].type">
		<div *ngSwitchCase="'array-multi'">
			<div class="factor-value-list" (click)="editFtCondition($event, row, column)">
				<div class="factor-value" *ngFor="let val of row[column.prop].list;" [hidden]="!val.selected">
					{{val.name}}
				</div>
				&nbsp; <!-- this is for use of empty cell click -->
			</div>
		</div>
		<div *ngSwitchCase="'array-single'">
			<!-- <select name="">
				<option *ngFor="let val of row[column.prop].list" [ngValue]="val">{{val.name}}</option>
			</select> -->
		</div>
		<div *ngSwitchCase="'boolean'" (click)="editFtCondition($event, row, column)">
			<label class="button-switch">
				<input type="checkbox" [(ngModel)]="row[column.prop].value" disabled="{{column.prop==='lbt_admin_flag'||column.prop==='lbt_sa_only'}}">
				<span class="switch-slider round">{{row[column.prop].value ? 'on' : 'off'}}</span>
			</label>
		</div>
		<div *ngSwitchCase="'date'">
			<input type="date" [(ngModel)]="row[column.prop].value">
		</div>
		<div *ngSwitchCase="null">{{value}}</div>
	</ng-container>
</ng-template>

<div #EditFactorMulti [hidden]="!selectedFactor || !selectedFactor.isEditing" class="edit-factor-multi">
	<div *ngFor="let opt of selectedFactor?.list; let idx = index;">
		<input type="checkbox" name="check-{{idx}}" [(ngModel)]="opt.selected">
		<label>{{opt.name}}</label>
	</div>
</div>
