<div>
	<div class="step-title">Photolog</div>
	<div class="step-content">
		<div class="top-content">
			<div>
				<label>LRS:</label>
				<select>
					<option>State LRS</option>
					<option></option>
				</select>
			</div>
			<div>
				<label>Image Type:</label>
				<select>
					<option>jpg</option>
					<option>png</option>
					<option>gif</option>
				</select>
			</div>
		</div>
		<div class="content">
			<div class="top-url">
				<label>Photolog root url:</label>
				<input type="url" placeholder="url">
			</div>
			<div *ngFor="let label of viewLabels" class="content-url">
				<label>{{label.name}}:</label>
				<input disabled placeholder="url pattern (read only)">
				<i class="glyphicons glyphicons-edit" title="Edit"></i>
			</div>
			<!-- <div>
				<label>Full-size Front View:</label>
				<input disabled placeholder="url pattern (read only)">
				<i class="glyphicons glyphicons-edit"></i>
			</div>
			<div>
				<label>Full-size Side View:</label>
				<input disabled placeholder="url pattern (read only)">
				<i class="glyphicons glyphicons-edit"></i>
			</div>
			<div>
				<label>Full-size Back View:</label>
				<input disabled placeholder="url pattern (read only)">
				<i class="glyphicons glyphicons-edit"></i>
			</div>
			<div>
				<label>Full-size Pavement View:</label>
				<input disabled placeholder="url pattern (read only)">
				<i class="glyphicons glyphicons-edit"></i>
			</div> -->
		</div>
	</div>
</div>
