<div class="table-paging" [style.line-height.px]="footerH"><!--
--><div class="line" [hidden]="isPaging && totalNum > 0">
		Total: {{totalNum}}
	</div><!--
--> <div class="line" [hidden]="!isPaging || totalNum <= 0">
		<div style="min-width: 90px;">{{fromNum}} - {{toNum}}</div>
		<div style="width: 16px;">of</div>
		<div style="min-width: 30px;margin-right:10px;">{{totalNum}}</div>
		<div class="icon">
			<i class="glyphicons glyphicons-step-backward" [ngClass]="{'disabled': pageNum===1}" (click)="goFirstPage()"></i>
		</div>
		<div class="icon">
			<i class="glyphicons glyphicons-rewind" [ngClass]="{'disabled': pageNum===1}" (click)="goPreviousPage()"></i>
		</div>
		<div class="pagenumber">
			<input type="number" name="pagenumber" [(ngModel)]="pageNum" (blur)="changePageNum()">
		</div>
		<div class="icon">
			<i class="glyphicons glyphicons-forward" [ngClass]="{'disabled': toNum>=totalNum}" (click)="goNextPage()"></i>
		</div>
		<div class="icon">
			<i class="glyphicons glyphicons-step-forward" [ngClass]="{'disabled': toNum>=totalNum}" (click)="goLastPage()"></i>
		</div>	
	</div>
</div>