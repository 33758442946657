<div>
	<div class="shape-section selected-style" (click)="toggleStyleBoard(true)">
		<ng-container [ngSwitch]="selectedShape">
			<div *ngSwitchCase="'SQR'" class="shape-square">
				<svg [attr.width]="selectedSize*3+15" [attr.height]="selectedSize*5+10">
					<rect x="0" y="0" width="100%" height="100%" [attr.fill]="color" stroke-width="0"></rect>
				</svg>
			</div>
			<div *ngSwitchCase="'CIR'" class="shape-circle">
				<svg [attr.width]="selectedSize*3+15" [attr.height]="selectedSize*5+10" viewBox="0 0 100 100">
					<circle cx="50" cy="50" [attr.r]="selectedSize*5+15" [attr.fill]="color"></circle>
				</svg>
			</div>
			<div *ngSwitchCase="'DIA'" class="shape-diamond">
				<svg [attr.width]="selectedSize*3+15" [attr.height]="selectedSize*5+10" viewBox="0 0 100 100">
					<polygon points="50,0 100,50 50,100 0,50" [attr.fill]="color"></polygon>
				</svg>
			</div>
			<div *ngSwitchCase="'TRI'" class="shape-triangle">
				<svg [attr.width]="selectedSize*3+15" [attr.height]="selectedSize*5+10" viewBox="0 0 100 100">
					<polygon points="50,0 0,100 100,100" [attr.fill]="color"></polygon>
				</svg>
			</div>
			<div *ngSwitchCase="'LNS'" class="shape-line">
				<svg width="30" height="30" viewBox="0 0 100 100">
					<line x1="0" y1="50" x2="100" y2="50" [attr.stroke]="color" [attr.stroke-width]="selectedSize*6"></line>
				</svg>
			</div>
		</ng-container>
	</div>
	<div *ngIf="showStyleBoard" class="style-board">
		<div *ngFor="let shape of shapes">
			<div *ngFor="let size of sizes" (click)="pickSizedStyle(shape, size)">
				<div class="shape-section" [ngClass]="{'current-style': shape===selectedShape&&size===selectedSize}">
					<ng-container [ngSwitch]="shape">
						<div *ngSwitchCase="'SQR'" class="shape-square">
							<svg [attr.width]="size*3+15" [attr.height]="size*5+10">
								<rect x="0" y="0" width="100%" height="100%" [attr.fill]="color" stroke-width="0"></rect>
							</svg>
						</div>
						<div *ngSwitchCase="'CIR'" class="shape-circle">
							<svg [attr.width]="size*3+15" [attr.height]="size*5+10" viewBox="0 0 100 100">
								<circle cx="50" cy="50" [attr.r]="size*2+35" [attr.fill]="color"></circle>
							</svg>
						</div>
						<div *ngSwitchCase="'DIA'" class="shape-diamond">
							<svg [attr.width]="size*3+15" [attr.height]="size*5+10" viewBox="0 0 100 100">
								<polygon points="50,0 100,50 50,100 0,50" [attr.fill]="color"></polygon>
							</svg>
						</div>
						<div *ngSwitchCase="'TRI'" class="shape-triangle">
							<svg [attr.width]="size*3+15" [attr.height]="size*5+10" viewBox="0 0 100 100">
								<polygon points="50,0 0,100 100,100" [attr.fill]="color"></polygon>
							</svg>
						</div>
						<div *ngSwitchCase="'LNS'" class="shape-line">
							<svg width="30" height="30" viewBox="0 0 100 100">
								<line x1="0" y1="50" x2="100" y2="50" [attr.stroke]="color" [attr.stroke-width]="size*6"></line>
							</svg>
						</div>
					</ng-container>
					<!-- <div
						class="sized-style shape-general"
						[style.background-color]="color"
						[style.font-size]="size*2+5"
						[ngClass]="{'shape-square':style==='SQR', 'shape-circle':style==='CIR', 'shape-triangle':style==='TRI', 'shape-diamond':style==='DIA', 'shape-line':style==='LNS'}">
					</div> -->
				</div>
			</div>
		</div>
	</div>
</div>
