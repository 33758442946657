<!-- <div class="nag-title">{{selectedNag?.name}}</div> -->
<div class="super-user">
	<div class="content-left">
		<div class="list-title customer-list-title">Organization List</div>
		<div class="customer-list-content">
			<div *ngFor="let cus of customers">
				<div class="customer-name" title="{{'cusID: ' + cus.id}}" [ngClass]="{'selected-customer': cus===customer}" (click)="onSelectCustomer(cus)" (contextmenu)="onCustomerContextmenu($event, cus)">{{cus.name}}</div>
				<div *ngIf="cus.isExpanded&&cus.children&&cus.children.length" class="children-container">
					<div *ngFor="let child of cus.children">
						<div class="customer-name" title="{{'cusID: ' + child.id}}" [ngClass]="{'selected-customer': child===customer}" (click)="onSelectCustomer(child, cus)" (contextmenu)="onCustomerContextmenu($event, child)">{{child.name}}</div>
						<div *ngIf="child.isExpanded&&child.children&&child.children.length" class="children-container">
							<div *ngFor="let grandChild of child.children">
								<div class="customer-name" title="{{'cusID: ' + grandChild.id}}" [ngClass]="{'selected-customer': grandChild===customer}" (click)="onSelectCustomer(grandChild, child, cus)" (contextmenu)="onCustomerContextmenu($event, grandChild)">{{grandChild.name}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="content-middle">
		<div class="subscription-list">
			<div class="list-title">Subscriptions</div>
			<div *ngIf="customer" class="subscription-list-table">
				<app-common-datagrid
					class="material"
					[columnMode]="'force'"
					[rows]="subscriptions"
					[columns]="subscriptionCols"
					[rowClass]="getRowClass.bind(this)"
					[hidePaging]="true"
					(select)="onSelectSub($event)"
				></app-common-datagrid>
			</div>
		</div>
		<div class="user-list" *ngIf="selectedSub">
			<div class="list-title">Users:</div>
			<div class="user-table">
				<app-common-datagrid
					#userTable
					[columns]="userCols"
					[rows]="userRows"
					[selectionType]="undefined"
					[hidePaging]="true"
					(tableContextmenu)="onTableContextmenu($event)"
				></app-common-datagrid>
			</div>
		</div>
	</div>
	<div class="content-right">
		<div class="admin-list" *ngIf="customer">
			<div class="list-title">Admin User:</div>
			<div *ngFor="let user of users" [hidden]="!user.isAdmin">{{user.userEmail}}</div>
		</div>
		<div class="auth-type" *ngIf="selectedAuth">
			<app-feat-auth-type-select [selectedAuth]="selectedAuth" (authChange)="onAuthChange($event)"></app-feat-auth-type-select>
		</div>
	</div>
	<div class="gistic-user">
		<div *ngIf="!gisticUser&&selectedSub&&selectedSub.state==='current'" class="insert-gistic">
			<button class="button-general" [disabled]="isAddingGisticUser" (click)="insertGisticUser()">Insert Gistic User</button>
		</div>
		<div *ngIf="gisticUser">
			<div class="attr-label">Gistic User: </div>
			 {{gisticUser.email}}
			<div class="attr-label">Customer: </div>
			 {{gisticUser.cusNameShort}}
			<button class="button-general" [disabled]="isDeletingGisticUser" (click)="removeGisticUser()">Remove</button>
		</div>
	</div>
</div>

<ng-template #subCellTempl let-row="row" let-column="column" let-value="value">
	<div title="{{'subID: ' + row.subId}}">
		{{value}}
	</div>
</ng-template>
<ng-template #roleCellTempl let-row="row" let-column="column" let-value="value">
	<div>
		<input type="radio" name="{{userRows.indexOf(row)}}-role"
		[(ngModel)]="row.role"
		[value] = "column.prop"
		disabled>
	</div>
</ng-template>

<context-menu #CustomerOptions>
	<ng-template contextMenuItem [enabled]="cusOptionsEnable.delete" (execute)="deleteCustomer($event.item)">Delete</ng-template>
</context-menu>
<context-menu #SubOptions>
	<ng-template contextMenuItem let-item [enabled]="subOptionsEnable.delete" (execute)="deleteUserSub($event.item)">Delete</ng-template>
</context-menu>
