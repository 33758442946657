<div class="app-modal-title">Upgrade</div>
<div class="app-modal-content">
	<div style="">
		<div class="sub-slider-wrapper">
			<div class="option-label">Subscription Level</div>
			<div class="option-content">
				<app-sub-level-slider #slider [sliderIdx]="currValue" (sliderChange)="updateSlider($event)"></app-sub-level-slider>
			</div>
		</div>
	</div>
</div>
<div class="app-modal-footer">
	<div class="button-group">
		<button class="button-general" md-raised-button (click)="close(false)">Cancel</button>
		<button class="button-general" md-raised-button (click)="close(true)" [disabled]="currValue===originalValue">Apply</button>
	</div>
</div>
