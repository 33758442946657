<div class="search-section">
	<i *ngIf="!searchStr" class="glyphicons glyphicons-search"></i>
	<input name="search" [(ngModel)]="searchStr" (ngModelChange)="changeSearchStr()">
</div>
<div class="layer-table-section">
	<app-common-datagrid
		#namesTable
		[columns]="columns"
		[rows]="filteredRows"
		[isPaging]="false"
		[hidePaging]="true">
	</app-common-datagrid>
</div>

<ng-template #referentCellTempl let-row="row" let-column="column">
	<div class="referent-cell">
		<i *ngIf="!row[column.prop]" class="glyphicons glyphicons-remove no-icon"></i>
		<i *ngIf="row[column.prop]" class="glyphicons glyphicons-ok yes-icon"></i>
	</div>
</ng-template>
<ng-template #sysColumnCellTempl let-row="row" let-value="value" let-index="index">
	<div class="edit-cell" (click)="editSysColumns(row)">
		<ng-container [ngSwitch]="value">
			<div *ngSwitchCase="'standard'">Standard</div>
			<div *ngSwitchCase="'missing'">Incomplete</div>
			<div *ngSwitchCase="'customized'">Custom</div>
		</ng-container>
		<div>
			<i class="glyphicons glyphicons-pencil"></i>
		</div>
	</div>
</ng-template>
<ng-template #seqCellTempl let-row="row" let-value="value" let-index="index">
	<div class="edit-cell" (click)="editSeqAndVisb(row)">
		<div>{{value==='standard' ? '' : value}}</div>
		<div>
			<i class="glyphicons glyphicons-pencil"></i>
		</div>
	</div>
</ng-template>