<div id="login-wrapper" class="auth-wrapper">
	<div class="auth-logo">
		<img [src]="imgUrl" class="auth-logo-img">
	</div>
	<form #loginForm="ngForm" class="auth-content-wrapper">
		<div class="auth-title">
			<label>Login</label>
		</div>
		<div>
			<input type="email" name="email" class="field-vertical" [(ngModel)]="email" placeholder="User Email" required/>
			<input type="password" name="password" class="field-vertical" [(ngModel)]="password" placeholder="Password" required/>			
		</div>		
		<div class="link-wrapper">
			<a [routerLink]="['/auth/retrieve-pw']" class="">Password Reset</a>
			<a [routerLink]="['/auth/register']" class="">Register</a>
		</div>
		<div class="btn-bottom-wrap">
			<button class="button-general" (click)="login()" [disabled]="!loginForm.form.valid || isLoggingin">Login</button>
		</div>
	</form>	
</div>