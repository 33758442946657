<div class="app-modal-title">Subscribe</div>
<div class="app-modal-content">
	<div class="options">
		<div class="same-line">
			<div class="option-label">Application:</div>
			<div class="option-content">
				{{appName}}
			</div>
		</div>
		<div class="same-line">
			<div class="option-label">Subscription Level:</div>
			<div class="option-content">
				<app-sub-level-slider #slider [sliderIdx]="subLevel.idx" (sliderChange)="updateSlider($event)"></app-sub-level-slider>
			</div>
		</div>
		<div class="same-line">
			<div class="option-label">Subscription Length:</div>
			<div class="option-content">
				<div class="same-line">
					<div>
						<input type="number" [(ngModel)]="quantity" (change)="updateQuantity()" required>
					</div>
					<div class="same-line line-second-part">
						<div *ngFor="let level of termLevels" class="same-line">
							<input type="radio" name="term" [(ngModel)]="selectedTerm" [value]="level.name" (change)="changeTermLevel()">
							<div>{{level.name}}</div>
						</div>
					</div>
				</div>
				<!-- <div class="sub-slider">
					<mat-slider min="0" max="1" step="1" [(ngModel)]="termSlider" (change)="changeTermLevel()"></mat-slider>
				</div>					 -->
			</div>
		</div>
		<div class="same-line">
			<label class="option-label">Start Date:</label>
			<div class="">
				<input type="date" [min]="today" [(ngModel)]="startDate" (change)="changeStartDate()" required>
			</div>
			<div class="same-line line-second-part">
				<div >End Date:</div>
				<div>
					<div>{{endDate}}</div>
				</div>
			</div>
		</div>
		<ng-container *ngIf="appName===azgivAppName">
			<div class="same-line">
				<div class="option-label">Discrepancy Agency Id:</div>
				<div class="option-content">
					<input type="text" name="givDomain" placeholder="e.g. {{givInfo.givState ? givInfo.givState.state + '911.' + givInfo.givState.code : '&lt;' + 'State' + '&gt;'}}.gov" [(ngModel)]="givInfo.discrepancyAgencyId">
				</div>
			</div>
		</ng-container>
	</div>
</div>
<div class="app-modal-footer">
	<div class="button-group">
		<button class="button-general" md-raised-button (click)="close(false)">Cancel</button>
		<button class="button-general" md-raised-button (click)="close(true)" [disabled]="!startDate||(appName===azgivAppName && !data.givState && (!givInfo.givState||!givInfo.discrepancyAgencyId))">Apply</button>
	</div>
</div>
