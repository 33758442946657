<div class="layer-columns-setting">
	<div class="col-unit layer-list">
		<div class="col-unit-title">Layers</div>
		<div class="col-unit-content">
			<div class="layer-item" *ngFor="let layer of layers" [ngClass]="{'selected-layer-item': layer===selectedLayer}" (click)="onSelectLayer(layer)">{{layer.name.toUpperCase()}}</div>
		</div>
	</div>
	<div class="col-unit visible-columns">
		<div class="col-unit-title">Visible Columns</div>
		<div class="col-unit-content">
			<ng-container *ngIf="selectedLayer&&selectedLayer.columns">
				<div class="column-item" *ngFor="let column of selectedLayer.columns; let idx = index;" (click)="toggleColumnVisib(idx)">
					<input name="{{'visib-' + idx}}" type="checkbox" [(ngModel)]="column.isVisib">
					<div>{{column.displayedName}}</div>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="col-unit tooltip-columns">
		<div class="col-unit-title">Tooltip Columns</div>
		<div class="col-unit-content">
			<ng-container *ngIf="selectedLayer&&selectedLayer.columns">
				<div class="column-item" *ngFor="let column of selectedLayer.columns; let idx = index;" (click)="toggleColumnTooltip(idx)">
					<input name="{{'tooltip-' + idx}}" type="checkbox" [(ngModel)]="column.isTooltip">
					<div>{{column.displayedName}}</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>