<div class="project-container">
	<label>Project</label>
	<select name="project" [(ngModel)]="selectedProject" (ngModelChange)="changeProject()">
		<option *ngFor="let proj of projects" [ngValue]="proj">{{proj.name}}</option>
	</select>
</div>

<div class="symbology-table">
	<app-common-datagrid
		#symbTable
		class="material"
		[columnMode]="'force'"
		[rows]="rows"
		[columns]="columns"
		[selectionType]="'single'"
		[hidePaging]="true"
	></app-common-datagrid>
</div>
<!-- <div class="add-icon">
	<i title="Add a symbology" class="glyphicons glyphicons-plus"></i>
</div> -->
<!-- <ng-template #projectCell let-row="row" let-value="value">
	<div *ngIf="row.isProjectRow" class="project-cell">
		<i
			class="glyphicons"
			[ngClass]="{'glyphicons-plus': !row.isExpand, 'glyphicons-minus': row.isExpand}"
			(click)="toggleProjectExpand(row)"
		></i>
		<div>{{value}}</div>
	</div>
</ng-template> -->
<!-- <ng-template #specCell let-row="row" let-value="value">
	<div *ngIf="!row.isProjectRow" class="spec-cell">

	</div>
</ng-template> -->
<ng-template #operationCell let-row="row" let-value="value">
	<div class="operation-cell">
		<i *ngIf="value==='add'" class="glyphicons glyphicons-plus" (click)="addSymbology()"></i>
		<ng-container *ngIf="value!=='add'">
			<i class="glyphicons glyphicons-edit" (click)="editSymbology(row)"></i>
			<i class="glyphicons glyphicons-bin" (click)="removeSymbology(row)"></i>
		</ng-container>
	</div>
</ng-template>
