<div class="app-modal-title">Users</div>
<div class="app-modal-content">
	<div>
		<div>
			<select name="users-list" [(ngModel)]="selectedUser">
				<option *ngFor="let user of users" [ngValue]="user">{{user.username}}</option>
			</select>
		</div>
	</div>
</div>
<div class="app-modal-footer">
	<div class="button-group">
		<button class="button-general" md-raised-button (click)="dialogRef.close(false)">Cancel</button>
		<button class="button-general" md-raised-button (click)="dialogRef.close(selectedUser)" [disabled]="!selectedUser">Add</button>
	</div>
</div>